"use client";
import { UserButton, useAuth } from "@clerk/nextjs";

export default function Header() {
  const { userId } = useAuth();
  
  return (
    <>
      {userId && (
        <div className="mx-4">
          <UserButton afterSignOutUrl="/" />
        </div>
      )}
    </>
  );
}
